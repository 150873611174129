<template>
  <v-container>
    <!-- Ürünler Tablosu -->
    <v-card>
      <v-card-title>
        <span class="headline">Ürünler</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="products"
        class="elevation-1"
      >
        <!-- Görsel alanı için özel tablo düzenlemesi -->
        <template #item.imageURL="{ item }">
          <v-img :src="item.imageURL" max-height="100" max-width="50" />
        </template>
        
        <template #item.finalPrice="{ item }">
          <span>{{ item.finalPrice  }}</span>
        </template>

        <!-- Satış Butonu -->
        <template #item.actions="{ item }">
          <v-btn color="success" @click="openSaleForm(item)">
            Satış Yap
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Siparişler Tablosu -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="headline">Mağaza Satışları</span>
      </v-card-title>
      <v-data-table
        :headers="orderHeaders"
        :items="orders"
        class="elevation-1"
      >
        <template #item.date="{ item }">
          {{ new Date(item.date).toLocaleString() }}
        </template>
      </v-data-table>
    </v-card>

    <!-- Satış Formu -->
    <v-dialog v-model="saleFormVisible" max-width="1200">
      <v-card>
        <v-card-title class="headline">Satış Formu</v-card-title>
        <v-card-text>
          <div class="sale-container">
            <!-- Ürün Görseli -->
            <div class="left-section">
              <v-img
                :src="saleData.productImage"
                max-height="300"
                max-width="250"
                contain
              ></v-img>
            </div>

            <!-- Ürün Bilgileri Sağ Taraf -->
            <div class="right-section">
              <v-form ref="saleForm" v-model="formValid">
                <v-text-field
                  v-model="saleData.productName"
                  label="Ürün İsmi"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="saleData.productBarcode"
                  label="Barkod"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="saleData.stock"
                  label="Stok"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="saleData.productSalesPrice"
                  label="Satış Fiyatı"
                  readonly
                ></v-text-field>
              </v-form>
            </div>
          </div>
        </v-card-text>
        <v-card-title class="headline">Müşteri Bilgileri</v-card-title>
        <v-card-text>
          <div class="sale-container">
            <div class="left-section-customer">
              <v-text-field
                v-model="saleData.customerFirstName"
                label="Ad"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="saleData.customerLastName"
                label="Soyad"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="saleData.customerPhone"
                label="Telefon"
                maxlength="11"
              ></v-text-field>
            </div>
            <div class="right-section-customer">
              <v-text-field
                v-model="saleData.customerTC"
                label="TC Kimlik Numarası"
                maxlength="11"
              ></v-text-field>
              <v-text-field
                v-model="saleData.customerAddress"
                label="Adres"
              ></v-text-field>
              <v-text-field
                v-model="saleData.customerEmail"
                label="E-posta"
                type="email"
              ></v-text-field>
            </div>
          </div>
          <v-text-field
            v-model="saleData.saleNote"
            label="Satış Notu"
          ></v-text-field>
        </v-card-text>
        <v-card-title class="headline">Tutar Bilgileri</v-card-title>
        <v-card-text>
          <div class="sale-container">
            <div class="right-section">
              <v-text-field
                v-model.number="saleData.quantity"
                label="Adet"
                type="number"
                :rules="[rules.required, rules.positiveNumber]"
                @blur="validateQuantity"
              />
              <v-text-field
                v-model="saleData.totalPrice"
                label="Ara Toplam (KDV Hariç)"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="saleData.kdvAmount"
                label="KDV (%20)"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="saleData.finalPrice"
                label="Toplam Fiyat (KDV Dahil)"
                readonly
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="submitSale">
            Onayla
          </v-btn>
          <v-btn color="white" @click="saleFormVisible = false">
            İptal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
    v-model="snackbar.visible"
    :timeout="3000"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      snackbar: {
        visible: false,
        text: "",
      },
      products: [],
      saleFormVisible: false,
      saleData: {
        productName: "",
        productBarcode: "",
        stock: "",
        productSalesPrice: "",
        productImage: "",
        customerFirstName: "",
        customerLastName: "",
        customerPhone: "",
        customerTC: "",
        customerAddress: "",
        customerEmail: "",
        saleNote: "",
        quantity: 1,
        totalPrice: 0,
        kdvAmount: 0,
        finalPrice: 0,
      },
      formValid: false,
      headers: [
        { text: "Görsel", value: "imageURL", sortable: false },
        { text: "Barkod", value: "barcode", sortable: true },
        { text: "Ürün İsmi", value: "name", sortable: true },
        { text: "Stok", value: "stock", sortable: true },
        { text: "Desi", value: "desi", sortable: true },
        { text: "KDV Dahil Satış Fiyatı", value: "finalPrice", sortable: true },
        { text: "İşlemler", value: "actions", sortable: false },
      ],
      orderHeaders: [
        { text: "Sipariş Tarihi", value: "date", sortable: true },
        { text: "Ürün İsmi", value: "productName", sortable: true },
        { text: "Barkod", value: "barcode", sortable: true },
        { text: "Adet", value: "quantity", sortable: true },
        { text: "Satış Fiyatı", value: "salePrice", sortable: true },
        { text: "Toplam Fiyat (KDV)", value: "totalAmount", sortable: true },
        { text: "Müşteri Adı", value: "customerFirstName", sortable: true },
        { text: "Müşteri Soyadı", value: "customerLastName", sortable: true },
        { text: "Müşteri Telefon", value: "customerPhone", sortable: true },
      ],
      rules: {
        required: (value) => !!value || "Bu alan zorunlu!",
        positiveNumber: (value) => value > 0 || "Adet sıfırdan büyük olmalı",
      },
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(
          "https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json"
        );
        this.products = response.data.map((item) => ({
          ...item,
          imageURL: item.imageURL || "https://via.placeholder.com/100",
          finalPrice: this.calculateFinalPrice(item.cost, item.comission),
        }));
      } catch (error) {
        console.error("Ürünler çekilirken bir hata oluştu.", error);
      }
    },
    async fetchOrders() {
      try {
        const response = await axios.get(
          "https://kitpanel-33ba2-default-rtdb.firebaseio.com/store/sell.json"
        );
        this.orders = Object.values(response.data || {}).map((order) => ({
          ...order,
          date: new Date(order.date).toISOString(),
        }));
      } catch (error) {
        console.error("Siparişler alınırken hata oluştu: ", error);
      }
    },
    showSnackbar(message) {
      this.snackbar.text = message;
      this.snackbar.visible = true;
    },
    validateQuantity() {
      if (parseInt(this.saleData.quantity) <= 0) {
        //alert("Adet bilgisi 0'dan büyük bir değer olmalı.");
        this.saleData.quantity = 1;
        this.calculateTotals();
      }
    },
    openSaleForm(item) {
      this.saleData.productName = item.name;
      this.saleData.productBarcode = item.barcode;
      this.saleData.stock = item.stock;
      this.saleData.productSalesPrice = this.calculateSellingPrice(
        item.cost,
        item.comission,
      );
      this.saleData.productImage = item.imageURL || "https://via.placeholder.com/150";

      this.saleData.quantity = 1; 
      this.calculateTotals();
      this.saleFormVisible = true;
    },
    calculateSellingPrice(cost, commission) {
      const numericCost = parseFloat(cost);
      const numericCommission = parseFloat(commission);
      return (numericCost + (numericCost * numericCommission) / 100).toFixed(2);
    },
    calculateTotals() {
      const quantity = parseInt(this.saleData.quantity) || 0;
      const salesPrice = parseFloat(this.saleData.productSalesPrice) || 0;
      this.saleData.totalPrice = (quantity * salesPrice).toFixed(2);
      this.saleData.kdvAmount = (this.saleData.totalPrice * 0.2).toFixed(2);
      this.saleData.finalPrice = (
        parseFloat(this.saleData.totalPrice) +
        parseFloat(this.saleData.kdvAmount)
      ).toFixed(2);
    },
    calculateFinalPrice(cost, commission) {
      const numericCost = parseFloat(cost) || 0;
      const numericCommission = parseFloat(commission) || 0;
      const salesPrice = numericCost + (numericCost * numericCommission) / 100;
      const kdvDahil = salesPrice * 1.2; // %20 KDV dahil hesaplama
      return kdvDahil.toFixed(2);
    },
    async submitSale() {
      try {
        if (
          !this.saleData.customerFirstName ||
          !this.saleData.customerLastName ||
          !this.saleData.customerPhone ||
          !this.saleData.customerTC ||
          !this.saleData.customerAddress
        ) {
          this.showSnackbar("Lütfen zorunlu alanları doldurunuz.");
          return;
        }

        if (parseInt(this.saleData.quantity) <= 0) {
          this.showSnackbar("Geçerli bir adet bilgisi giriniz.");
          return;
        }

        // Ürünün stok indeksini tespit et
        const productIndex = this.products.findIndex(
          (product) => product.barcode === this.saleData.productBarcode
        );

        if (productIndex === -1) {
          alert("Ürün bulunamadı.");
          return;
        }

        // Güncellenmiş stok değeri
        const updatedStock = parseInt(this.products[productIndex].stock) - parseInt(this.saleData.quantity);

        if (updatedStock < 0) {
          alert("Yeterli stok yok.");
          return;
        }

        // Stok değerini Firebase'e PATCH et
        await axios.patch(
          `https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol/${productIndex}.json`,
          { stock: updatedStock.toString() }
        );

        // Satış detaylarını Firebase'e ekle
        const saleDetails = {
          productName: this.saleData.productName,
          barcode: this.saleData.productBarcode,
          customerFirstName: this.saleData.customerFirstName,
          customerLastName: this.saleData.customerLastName,
          customerPhone: this.saleData.customerPhone,
          customerTC: this.saleData.customerTC,
          customerAddress: this.saleData.customerAddress,
          customerEmail: this.saleData.customerEmail,
          saleNote: this.saleData.saleNote,
          salePrice: this.saleData.productSalesPrice,
          quantity: this.saleData.quantity,
          kdvAmount: this.saleData.kdvAmount,
          totalAmount: this.saleData.finalPrice,
          date: new Date().toISOString(),
        };

        await axios.post(
          "https://kitpanel-33ba2-default-rtdb.firebaseio.com/store/sell.json",
          saleDetails
        );

        this.showSnackbar("Satış işlemi başarılı!");
        this.saleFormVisible = false;
        this.fetchProducts();
        this.fetchOrders();
      } catch (error) {
        this.showSnackbar("Bir hata oluştu.");
        console.error("Hata:", error);
      }
    },
  },
  watch: {
    "saleData.quantity": function () {
      this.validateQuantity();
      this.calculateTotals();
    },
  },
  rules: {
    required: (value) => !!value || "Bu alan zorunlu!",
    positiveNumber: (value) => parseInt(value) > 0 || "Adet sıfırdan büyük olmalı",
  },
  mounted() {
    this.fetchProducts();
    this.fetchOrders();
  },
};
</script>

<style scoped>
.sale-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-section{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right-section {
  flex: 3;
  display: flex;
  flex-direction: column;
}


.left-section-customer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 4px
}

.right-section-customer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px
}

</style>
